<template>
    <Modal :modal-title="title"
           :dialog="customerFormVisibility"
           max-width="1600px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           :retain-focus="false"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <v-row v-if="loader">
                <v-col v-for="i in 3" :key="i" cols="12" md="4">
                    <v-skeleton-loader width="100%"
                                       height="400"
                                       type="card"
                    ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-if="editedCustomerId">
                    <v-skeleton-loader width="100%"
                                       height="200"
                                       type="table"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <ManageCustomerForm v-if="!loader" ref="form"></ManageCustomerForm>
            <SitesPerCustomer :edit="true"
                               v-if="!loader && editedCustomerId !== null"
                               class="mt-8"
                               ref="sitesTable"
                               @check-customer="checkCustomer"
                               data-test="SitesPerCustomers"></SitesPerCustomer>

            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>
            <ConfirmationDialog :dialog="showInfoDialog"
                                @confirm="showInfoDialog = false"
                                :text="$t('customer_form.add_title')"
                                :hideFalseButton="true"
                                :action-button-title="$t('buttons.ok')"></ConfirmationDialog>
            <span data-test="testKeydownSpan"></span>

            <ValidationError v-if="showError"></ValidationError>
        </template>

        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.close') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import Modal from '@/components/templates/Modal';
import ManageCustomerForm from '@/components/customers/manageCustomer/ManageCustomerForm';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import SitesPerCustomer from '@/components/customers/sitesPerCustomer/SitesPerCustomer';
import ValidationError from '@/components/widgets/ValidationError';
import { mapState } from 'vuex';

export default {
    name: 'ManageCustomer',
    components: { Modal, ManageCustomerForm, ConfirmationDialog, SitesPerCustomer, ValidationError },
    data: () => ({
        showConfirmationDialog: false,
        showInfoDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapFields('manageCustomer', [
            'customerFormVisibility',
            'editedCustomerId'
        ]),
        ...mapState('manageCustomer', [
            'activeCustomer'
        ]),
        title () {
            return this.editedCustomerId ? this.$t('customer_form.edit_title') : this.$t('customer_form.add_title');
        },
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        }
    },
    methods: {
        checkCustomer () {
            this.$refs.form.validate()
                .then(valid => {
                    if (valid) {
                        this.$refs.sitesTable.visible();
                    } else this.showInfoDialog = true;
                });
        },
        close () {
            this.customerFormVisibility = false;
            this.$store.commit('customers/SET_FOCUSED_FIELD', null);
            this.$store.commit('manageSite/RESET_NESTED_SITES');
            this.$store.commit('manageLocation/RESET_NESTED_LOCATIONS');
            this.$store.commit('manageSite/SET_IS_FORM_FILLED', false);
            this.$store.commit('manageLocation/SET_IS_LOCATION_FORM_FILLED', false);
            this.$store.commit('manageCustomer/CLEAR_CUSTOMER_DATA');
            this.showError = false;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.$emit('close');
        },
        visibility (event) {
            this.customerFormVisibility = event;
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        updateCustomer () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.loader = true;
                        return this.$store.dispatch('manageCustomer/update')
                            .then(() => {
                                this.$emit('fetch', this.editedCustomerId);
                                this.$store.dispatch('manageCustomer/getActiveCustomer', this.editedCustomerId).then(() => {
                                    this.$store.commit('manageCustomer/SET_CUSTOMER_DATA', this.activeCustomer);
                                });
                            })
                            .finally(() => {
                                this.loader = false;
                                this.$store.commit('manageSite/RESET_NESTED_SITES');
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        createCustomer () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageCustomer/create')
                            .then(() => {
                                this.$emit('fetch');
                            })
                            .finally(() => {
                                this.loader = false;
                                this.editedCustomerId = this.activeCustomer.id;
                                this.$store.commit('manageSite/RESET_NESTED_SITES');
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.editedCustomerId) {
                this.updateCustomer();
            } else {
                this.createCustomer();
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
