<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="wrapper">
        <TableTitle :title="$t('labels.saved_locations')"
                    @click="addLocation"
                    :button-visibility="canAddLocation && edit"
                    :disable-button="!pointCreationAvailable"
                    :button-title="$t('buttons.add_new_location')"></TableTitle>
        <p v-if="!pointCreationAvailable" class="text-right mt-1 text--xs red--text">
            {{ $t('messages.point_limit_exceeded') }}</p>
        <LocationsPerSiteTable class="mt-3"
                               @switch="handleSwitch($event)"
                               :edit="edit"></LocationsPerSiteTable>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import LocationsPerSiteTable from '@/components/customers/locationsPerSite/LocationsPerSiteTable';
import TableTitle from '@/components/widgets/tables/TableTitle';
import { ICONS } from '@/constants/icons';

export default {
    name: 'LocationsPerSite',
    components: { LocationsPerSiteTable, TableTitle },
    props: {
        edit: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        ICONS
    }),
    computed: {
        ...mapFields('manageLocation', [
            'locationFormVisibility',
            'editedLocationId'
        ]),
        ...mapState('customers', [
            'pointCreationAvailable'
        ]),
        ...mapState('manageLocation', [
            'nestedLocations'
        ]),
        canAddLocation () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.LOCATION);
        },
        headers () {
            return [
                {
                    text: this.$t('labels.location_name'),
                    value: 'name',
                    sortable: false
                },
                {
                    text: this.$t('labels.beacon_identifier'),
                    value: 'beaconIdentifier',
                    sortable: false
                },
                {
                    text: this.$t('labels.location_code'),
                    value: 'code',
                    sortable: false
                },
                {
                    text: this.$t('labels.nfc_tag'),
                    value: 'nfccode',
                    sortable: false
                },
                {
                    text: this.$t('management.customers_and_sites.sites_table.headers.actions'),
                    value: 'actions',
                    sortable: false,
                    width: '120px'
                }
            ];
        }
    },
    methods: {
        addLocation () {
            this.editedLocationId = null;
            this.locationFormVisibility = true;
        },
        handleSwitch ($event) {
            this.$emit('switch', $event);
        },
        unmount (el) {
            this.$store.commit('manageLocation/REMOVE_NESTED_LOCATION', el);
        }
    }
};
</script>

<style scoped lang="scss">
.wrapper {
    width: 100%;
}
</style>
