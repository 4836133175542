import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchCities: null,
        searchCitiesAndZipCode: null,
        searchZipCodes: null,
        isCitiesLoading: false,
        debounceGetCities: null,
        citiesPaginationPage: 1
    }),
    computed: {
        ...mapState('cities', [
            'totalCitiesAmount',
            'rawCities'
        ])
    },
    watch: {
        searchCities: {
            handler (val) {
                if (!val || this.isCitiesLoading) {
                    return;
                }

                const title = val.split(' ');

                this.debounceGetCities(title[1] || title[0]);
            }
        },
        searchZipCodes: {
            handler (val) {
                if (val && val.length > 3) {
                    const title = {
                        'filter[zip_code][match]': `${val}`
                    };

                    this.debounceGetCities(title);
                }
            }
        },
        searchCitiesAndZipCode: {
            handler (val) {
                if (val && val.length > 2) {
                    if (val.match(/\d+/g)) {
                        const zipCode = val.match(/\d+/g).join('-') || '';
                        const cityName = this.getLongestStr(val.match(/[a-zA-Z]+/g));
                        if (zipCode.length > 2) {
                            const title = {
                                'filter[zip_code][match]': `${zipCode}`
                            };
                            this.debounceGetCities(title);
                        } else if (cityName.length > 2) {
                            const title = {
                                'filter[name][match]': `${cityName}`
                            };
                            this.debounceGetCities(title);
                        }
                    } else {
                        const city = val.split(' ');
                        const title = {
                            'filter[name][match]': `${city[1] || city[0]}`
                        };
                        this.debounceGetCities(title);
                    }
                }
            }
        }
    },
    methods: {
        getLongestStr (arr) {
            if (arr && Array.isArray(arr)) {
                return arr.sort(
                    function (a, b) {
                        return b.length - a.length;
                    }
                )[0];
            } else return '';
        },
        getCities (additionalParams = null) {
            this.isCitiesLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params['filter[name][prefix]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            } else if (typeof additionalParams === 'number' && !isNaN(additionalParams)) {
                params['filter[id]'] = additionalParams;
            }

            this.$store.dispatch('cities/getCities', params)
                .then(() => {
                    this.citiesPaginationPage = 1;
                })
                .finally(() => {
                    this.isCitiesLoading = false;
                });
        },
        getCitiesLazyLoading (cityId = null) {
            if (this.totalCitiesAmount > this.rawCities.length) {
                this.isCitiesLoading = true;

                this.citiesPaginationPage += 1;

                const params = {};
                if (cityId) {
                    let data = cityId;
                    data = Array.isArray(data) ? data.join(',') : data;
                    params['filter[id]'] = data;
                }

                params['page[number]'] = this.citiesPaginationPage;

                const _name = this.searchCities || this.filtersSearching?.[FILTERS_SEARCHING.CITY];

                if (_name) {
                    params['filter[name][prefix]'] = _name;
                }

                this.$store.dispatch('cities/getCitiesLazyLoading', params)
                    .finally(() => {
                        this.isCitiesLoading = false;
                    });
            }
        }
    },
    created () {
        this.debounceGetCities = debounce(this.getCities, 1000);
    }
};
