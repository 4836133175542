<template>
    <Modal :modal-title="title"
           :dialog="locationFormVisibility"
           max-width="1600px"
           dense
           :persistent="true"
           :closeIcon="false"
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <v-tabs v-model="activeTab"
                    show-arrows
                    class="mb-2"
                    centered>
                <v-tab v-for="(tab, i) in tabs"
                       :to="setHref(i)"
                       :key="i">{{ tab }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="activeTab">
                <v-tab-item>
                    <ManageLocationForm ref="form"></ManageLocationForm>
                    <LocationAccess class="mt-8"
                                    ref="locationAccess"></LocationAccess>
                </v-tab-item>
            </v-tabs-items>
            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>
            <span data-test="testKeydownSpan"></span>

            <ValidationError v-if="showError"></ValidationError>
        </template>

        <template slot="actions">
            <template>
                <v-layout align-center justify-end>
                    <v-btn color="secondary"
                           outlined
                           class="mr-3"
                           @click="close">{{ $t('buttons.close') }}</v-btn>
                    <v-btn color="secondary"
                           :loading="loader"
                           :disabled="loader || hasLimitedAccessToAllowEditing"
                           data-test="saveBtn"
                           @click="save">{{ $t('buttons.save') }}</v-btn>
                </v-layout>
                <span v-if="hasLimitedAccessToAllowEditing" class="limitedAccessInfo">{{ $t('messages.has_limited_access') }}</span>
            </template>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Modal from '@/components/templates/Modal';
import ManageLocationForm from '@/components/customers/manageLocation/ManageLocationForm';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ValidationError from '@/components/widgets/ValidationError';
import LocationAccess from '@/components/customers/manageLocation/LocationAccess';

export default {
    name: 'ManageLocation',
    components: { Modal, ManageLocationForm, LocationAccess, ConfirmationDialog, ValidationError },
    data: () => ({
        activeTab: null,
        selectAllInspections: null,
        selectAllIncidents: null,
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapState('manageSite', [
            'activeSite'
        ]),
        ...mapState('manageCustomer', [
            'activeCustomer'
        ]),
        ...mapState('manageSite', [
            'siteFormVisibility'
        ]),
        ...mapState('manageLocation', [
            'showLocationDetails',
            'hasLimitedAccessToAllowEditing'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility',
            'editedLocationId'
        ]),
        title () {
            return this.editedLocationId ? this.$t('location_form.edit_title') : this.$t('location_form.add_title');
        },
        isFieldsDirty () {
            return this.$refs.form.isFormDirty || this.$refs.locationAccess.isFormDirty;
        },
        tabs () {
            return [this.$t('location_form.tabs.general')];
        }
    },
    methods: {
        close () {
            this.editedLocationId = null;
            this.activeTab = null;
            this.showError = false;
            this.$store.commit('manageLocation/RESET_LOCATION_DATA');
            this.$store.commit('customers/SET_FOCUSED_FIELD', null);
            if (!this.showLocationDetails) {
                this.$store.dispatch('manageLocation/cleanActiveLocationInformation');
            }
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.locationFormVisibility = false;
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        visibility (event) {
            this.locationFormVisibility = event;
        },
        setHref (i) {
            if (i === 'planning') {
                return { name: 'scheduling' };
            }
            return null;
        },
        updateSite () {
            return this.$store.commit('customers/SET_UPDATE', true);
        },
        createLocation () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageLocation/createLocation')
                            .then(() => {
                                this.$emit('close-manage');
                            }).then(() => {
                                this.locationFormVisibility = false;
                                this.updateSite();
                                this.loader = false;
                                this.close();
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updateLocation () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;

                    if (valid) {
                        return this.$store.dispatch('manageLocation/updateLocation')
                            .then(() => {
                                this.$emit('close-manage');
                            })
                            .then(() => {
                                this.locationFormVisibility = false;
                                this.updateSite();
                                this.loader = false;
                                this.close();
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        filledForm () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.locationFormVisibility = false;
                        this.$store.commit('manageLocation/SET_NESTED_LOCATIONS');
                        this.$store.commit('manageLocation/SET_IS_LOCATION_FORM_FILLED', true);
                        this.$store.commit('manageLocation/RESET_LOCATION_DATA');
                        this.loader = false;
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.editedLocationId) {
                this.updateLocation();
            } else {
                this.createLocation();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables";

    .limitedAccessInfo {
        font-size: 12px;
        color: $light_grey_2;
        display: block;
        text-align: right;
    }
</style>
