<template>
    <Modal :modal-title="$t('configurations.imports.import_customers')"
           :dialog="dialog"
           max-width="800px"
           dense
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ImportCustomersForm ref="form"
                                @fetch="$emit('fetch')"></ImportCustomersForm>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import ImportCustomersForm from '@/components/customers/imports/ImportCustomersForm';

export default {
    name: 'ImportCustomersModal',
    components: { Modal, ImportCustomersForm },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        close () {
            this.$emit('close');
            this.$refs.form.clearFile();
        },
        visibility (event) {
            this.$emit('visibility', event);
        }
    }
};
</script>

<style scoped>

</style>
