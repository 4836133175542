<template>
    <Modal :modal-title="$t('configurations.imports.import_locations')"
           :dialog="dialog"
           max-width="800px"
           dense
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ImportLocationsForm ref="form"
                             @fetch="$emit('fetch')"></ImportLocationsForm>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import ImportLocationsForm from '@/components/customers/imports/ImportLocationsForm';

export default {
    name: 'ImportLocationsModal',
    components: { Modal, ImportLocationsForm },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        close () {
            this.$emit('close');
            this.$refs.form.clearFile();
        },
        visibility (event) {
            this.$emit('visibility', event);
        }
    }
};
</script>

<style scoped>

</style>
