var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.locationsPerSite,"disable-pagination":"","dense":"","loading":_vm.loading,"hide-default-footer":"","disable-sort":""},on:{"dblclick:row":_vm.showRowDetails},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.handleView(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocalDateTime(item.updated_at))+" ")]}},_vm._l((_vm.headers),function(headerItem,i){return {key:("header." + (headerItem.value)),fn:function(ref){
var header = ref.header;
return [_c('SortableHeader',{key:i,attrs:{"header-item":header},on:{"sort":function($event){return _vm.sort($event)}}})]}}}),{key:"item.special_attention",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[(item.special_attention)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.ICONS.CHECK))]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('HintingIcon',{attrs:{"icon":_vm.ICONS.DETAILS,"tooltip":_vm.$t('actions.view'),"id":item.id,"class-name":"mr-2"},on:{"click":function($event){return _vm.handleView(item)}}}),(_vm.canEditLocation)?_c('HintingIcon',{attrs:{"icon":_vm.ICONS.EDIT,"tooltip":_vm.$t('actions.edit'),"class-name":"mr-2"},on:{"click":function($event){return _vm.editItem(item)}}}):_vm._e(),(_vm.canRemoveLocation)?_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"callback":_vm.deleteLocation}}):_vm._e(),_c('GetFile',{attrs:{"with-title":false,"loader":_vm.getLoader(item.id)},on:{"get-file":function($event){return _vm.getQRCode(item.id)}}},[_c('template',{slot:"icon"},[_c('HintingIcon',{attrs:{"icon":_vm.ICONS.QR_CODE,"tooltip":_vm.$t('management.customers_and_sites.locations_table.actions.qr'),"id":item.id}})],1)],2)],1)]}}],null,true)}),_c('Pagination',{attrs:{"pagination":_vm.pagination},on:{"change-page":function($event){return _vm.changePage($event)},"change-amount":function($event){return _vm.changeAmount($event)}}}),(_vm.showLocationDetails)?_c('LocationDetails',{attrs:{"dialog":_vm.showLocationDetails},on:{"visibility":function($event){_vm.showLocationDetails = $event},"close":_vm.close}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }