<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.customers_and_sites') }}</h1>
        <FiltersBar></FiltersBar>
        <v-layout align-center
                  justify-space-between
                  class="mb-8">
            <v-flex>
                <ButtonWithConfirmation v-if="enableRefreshGeo"
                                        :callback="refreshPointsGeolocation"
                                        :confirmation-text="$t('messages.refresh_geolocation_alert')"
                                        :action-button-title="$t('buttons.refresh')">
                    <a class="mr-3"
                       v-if="canRefreshGeolocation">
                        <v-icon size="20">{{ ICONS.GEOLOCATION }}</v-icon>
                        {{ $t('buttons.refresh_geoloc') }}
                    </a>
                </ButtonWithConfirmation>
                <template v-if="canImportData">
                <a class="mr-3"
                   @click="showImportCustomers = true">
                    <v-icon>{{ ICONS.IMPORT }}</v-icon>
                    {{ $t('configurations.imports.import_customers') }}
                </a>
                <a class="mr-3"
                   @click="showImportSites = true">
                    <v-icon>{{ ICONS.IMPORT }}</v-icon>
                    {{ $t('configurations.imports.import_sites') }}
                </a>
                <a @click="showImportLocations = true">
                    <v-icon>{{ ICONS.IMPORT }}</v-icon>
                    {{ $t('configurations.imports.import_locations') }}
                </a>
                </template>
            </v-flex>
            <v-flex>
                <v-layout justify-end>
                    <v-btn color="secondary"
                           v-if="canAddCustomer"
                           data-test="addNewCustomerBtn"
                           @click="showCustomerForm()">{{ $t('buttons.add_new_customer') }}</v-btn>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-data-table :headers="headers"
                      :items="customers"
                      disable-sort
                      :loading="loader"
                      disable-pagination
                      @dblclick:row="showRowDetails"
                      hide-default-footer
                      class="elevation-1 mt-8">

            <template v-slot:header.name="{ header }">
                <SortableHeader @sort="sort($event)"
                                data-test="sortableHeader"
                                :header-item="header"></SortableHeader>
            </template>
            <template v-slot:header.territory_name="{ header }">
                <SortableHeader @sort="sort($event)"
                                :header-item="header"></SortableHeader>
            </template>
            <template v-slot:header.address="{ header }">
                <SortableHeader @sort="sort($event)"
                                :header-item="header"></SortableHeader>
            </template>
            <template v-slot:header.actions="{ header }">
                <SortableHeader @sort="sort($event)"
                                :header-item="header"></SortableHeader>
            </template>

            <template v-slot:item.name="{ item }">
                <span class="clickable"
                      data-test="handleViewName"
                      @click="handleView(item)">{{ item.name }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
                <HintingIcon :icon="ICONS.DETAILS"
                             :tooltip="$t('actions.view')"
                             :id="item.id"
                             class-name="mr-2"
                             data-test="handleViewBtn"
                             @click="handleView(item)"></HintingIcon>

                <HintingIcon :icon="ICONS.EDIT"
                             :tooltip="$t('actions.edit')"
                             :id="item.id"
                             v-if="canEditCustomer"
                             data-test="showCustomerFormBtn"
                             @click="showCustomerForm(item)"
                             class-name="mr-2"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canRemoveCustomer"
                                        :callback="deleteCustomer"></ButtonWithConfirmation>

                <GetAllPointsQrCodes :customer-id="item.id"></GetAllPointsQrCodes>
            </template>
        </v-data-table>
        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>

        <CustomerDetails v-if="showCustomerDetails"
                         data-test="CustomerDetails"
                         :dialog="showCustomerDetails"
                         @close="close"
                         @visibility="showCustomerDetails = $event"></CustomerDetails>

        <ManageCustomer data-test="manageCustomer"
                        @close="closeManageCustomer"
                        @fetch="refreshData($event)"></ManageCustomer>
        <ManageSite v-if="siteFormVisibility"
                    data-test="ManageSite"></ManageSite>
        <ManageLocation v-if="locationFormVisibility" data-test="ManageLocation"></ManageLocation>
        <ManageCustomerContact v-if="contactFormVisibility"></ManageCustomerContact>

        <InformationDialog :dialog="showInformation"
                           :text="$t('messages.refresh_geolocation')"
                           @close="showInformation = false"
                           @visibility="showInformation = $event"></InformationDialog>

        <ImportCustomersModal :dialog="showImportCustomers"
                              @fetch="fetchCustomers"
                              @close="showImportCustomers = false"
                              @visibility="showImportCustomers = $event"></ImportCustomersModal>

        <ImportSitesModal :dialog="showImportSites"
                          @close="showImportSites = false"
                          @visibility="showImportSites = $event"></ImportSitesModal>

        <ImportLocationsModal :dialog="showImportLocations"
                              @close="showImportLocations = false"
                              @visibility="showImportLocations = $event"></ImportLocationsModal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import debounce from 'lodash.debounce';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import HintingIcon from '@/components/widgets/HintingIcon';
import CustomerDetails from '@/components/customers/CustomerDetails';
import ManageCustomer from '@/components/customers/manageCustomer/ManageCustomer';
import ManageSite from '@/components/customers/manageSite/ManageSite';
import ManageLocation from '@/components/customers/manageLocation/ManageLocation';
import ManageCustomerContact from '@/components/customers/ManageCustomerContact';
import Pagination from '@/components/widgets/tables/Pagination';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import InformationDialog from '@/components/widgets/dialogs/InformationDialog';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import GetAllPointsQrCodes from '@/components/customers/GetAllPointsQrCodes';
import ImportCustomersModal from '@/components/customers/imports/ImportCustomersModal';
import ImportSitesModal from '@/components/customers/imports/ImportSitesModal';
import ImportLocationsModal from '@/components/customers/imports/ImportLocationsModal';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import debouncedCities from '@/mixins/debounced/debouncedCities';
import debouncedTerritories from '@/mixins/debounced/debouncedTerritories';
import filtering from '@/mixins/filtering';
import { ICONS } from '@/constants/icons';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import ability from '@/mixins/ability';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'CustomersAndSites',
    mixins: [debouncedCities, debouncedTerritories, filtering, ability, paginationSettings],
    components: {
        FiltersBar,
        HintingIcon,
        CustomerDetails,
        ManageCustomer,
        ManageSite,
        ManageLocation,
        ManageCustomerContact,
        Pagination,
        SortableHeader,
        ButtonWithConfirmation,
        InformationDialog,
        GetAllPointsQrCodes,
        ImportCustomersModal,
        ImportSitesModal,
        ImportLocationsModal
    },
    data: (vm) => ({
        enableRefreshGeo: false,
        showCustomerDetails: false,
        pagination: vm.setupPagination(),
        loader: false,
        sortable: {
            name: null
        },
        debounceFetchCustomers: null,
        showInformation: false,
        showImportCustomers: false,
        showImportSites: false,
        showImportLocations: false,
        ICONS
    }),
    computed: {
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.TERRITORY_ID,
                    label: this.$t('labels.territories'),
                    multiple: true,
                    loading: true,
                    search: FILTERS_SEARCHING.TERRITORY,
                    lazy: this.getTerritoriesLazyLoading,
                    searchCallback: this.debounceGetTerritories,
                    items: {
                        obj: 'state',
                        module: 'generalConfigurations',
                        prop: 'territories'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.CITY_ID,
                    label: this.$t('labels.cities'),
                    multiple: true,
                    loading: true,
                    search: FILTERS_SEARCHING.CITY,
                    lazy: this.getCitiesLazyLoading,
                    searchCallback: this.debounceGetCities,
                    items: {
                        obj: 'getters',
                        module: 'cities',
                        prop: 'filterableCities'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.SITE_GROUP_ID,
                    label: this.$t('labels.object_groups'),
                    multiple: true,
                    loading: true,
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'siteGroups'
                    }
                })
            ];
        },
        headers () {
            return [
                {
                    text: this.$t('management.customers_and_sites.headers.name'),
                    value: 'name',
                    sortable: true
                },
                {
                    text: this.$t('management.customers_and_sites.headers.territory'),
                    value: 'territory_name',
                    sortable: false
                },
                {
                    text: this.$t('management.customers_and_sites.headers.address'),
                    value: 'address',
                    sortable: false
                },
                {
                    text: this.$t('labels.sites'),
                    value: 'places_amount',
                    sortable: false
                },
                {
                    text: this.$t('labels.locations'),
                    value: 'points_amount',
                    sortable: false
                },
                {
                    text: this.$t('management.customers_and_sites.headers.actions'),
                    value: 'actions',
                    width: '170px',
                    sortable: false
                }
            ];
        },
        ...mapState('customers', [
            'customers',
            'totalCustomersAmount'
        ]),
        ...mapState('manageCustomer', [
            'activeCustomer'
        ]),
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        ...mapFields('manageCustomer', [
            'customerFormVisibility',
            'editedCustomerId'
        ]),
        ...mapFields('manageSite', [
            'siteFormVisibility'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility'
        ]),
        ...mapFields('manageCustomerContact', [
            'contactFormVisibility'
        ]),
        canAddCustomer () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.CUSTOMER);
        },
        canEditCustomer () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.CUSTOMER);
        },
        canRemoveCustomer () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.CUSTOMER);
        },
        canRefreshGeolocation () {
            return this.$can(this.$abilityActions.REFRESH_LOCATIONS_GEOLOCATION, this.$abilitySubjects.CUSTOMER);
        }
    },
    watch: {
        totalCustomersAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debounceFetchCustomers();
                }
            }
        }
    },
    methods: {
        getActiveCustomer (id) {
            return this.$store.dispatch('manageCustomer/getActiveCustomer', id);
        },
        handleView (item) {
            this.getActiveCustomer(item.id)
                .then(() => {
                    this.showCustomerDetails = true;
                });
        },
        close () {
            this.$store.commit('manageCustomer/SET_ACTIVE_CUSTOMER', null);
            this.$store.commit('customers/SET_SITES_PER_CUSTOMERS', []);
            this.showCustomerDetails = false;
        },
        closeManageCustomer () {
            if (!this.showCustomerDetails) {
                this.$store.commit('manageCustomer/SET_ACTIVE_CUSTOMER', null);
                this.$store.commit('customers/SET_SITES_PER_CUSTOMERS', []);
            }
        },
        showCustomerForm (item = null) {
            if (item) {
                this.editedCustomerId = item.id;
                this.getActiveCustomer(item.id)
                    .then(() => {
                        this.$store.commit('manageCustomer/SET_CUSTOMER_DATA', this.activeCustomer);
                        this.customerFormVisibility = true;
                    });
            } else {
                this.editedCustomerId = null;
                this.customerFormVisibility = true;
            }
        },
        fetchCustomers () {
            this.loader = true;
            const _params = {
                'page[number]': this.pagination.page,
                'extra_fields[customers]': 'places_amount,points_amount',
                // 'include': 'places.points,city.territory',
                'page[size]': this.pagination.amount
            };

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    _params[`filter[${f}]`] = this.filters[f].join(',');
                }
            });

            const _sortColumn = Object.keys(this.sortable).find(k => this.sortable[k] !== null);

            if (_sortColumn) {
                _params.sort = this.sortable[_sortColumn] === 'asc' ? _sortColumn : `-${_sortColumn}`;
            }

            if (this.search) {
                _params['filter[search]'] = this.search;
            }

            this.$store.dispatch('customers/getCustomers', _params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchCustomers();
        },
        changeAmount (event) {
            this.pagination.amount = event;
            this.fetchCustomers();
        },
        sort (event) {
            Object.keys(this.sortable).forEach(k => {
                if (k !== event) {
                    this.sortable[k] = null;
                }
            });

            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'asc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.fetchCustomers();
        },
        refreshData (event) {
            setTimeout(() => {
                if (this.showCustomerDetails) {
                    this.getActiveCustomer(event);
                }
                this.fetchCustomers();
            }, 100);
        },
        deleteCustomer (id) {
            return this.$store.dispatch('manageCustomer/deleteCustomer', id)
                .then(() => {
                    this.fetchCustomers();
                });
        },
        refreshPointsGeolocation () {
            return this.$store.dispatch('customers/refreshPointsGeolocation')
                .then(() => {
                    this.showInformation = true;
                });
        },
        showRowDetails (event, { item }) {
            this.handleView(item);
        }
    },
    created () {
        this.debounceFetchCustomers = debounce(this.fetchCustomers, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debounceFetchCustomers);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.CUSTOMER)) {
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('generalConfigurations/getTerritories')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.TERRITORY_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('cities/getCities')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.CITY_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('sites/getSiteGroups')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.SITE_GROUP_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('employees/getEmployeesWithAccess');
                vm.$store.dispatch('employees/getInspectors');
                vm.fetchCustomers();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        next();
    }
};
</script>

<style scoped lang="scss">
</style>
