<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="wrapper pb-3">
        <TableTitle :title="$t('labels.saved_sites')"
                    @click="showSiteForm"
                    data-test="TableTitle"
                    :button-visibility="canAddSite && edit"
                    :button-title="$t('buttons.add_new_site')"></TableTitle>
        <SitesPerCustomerTable class="mt-3"
                               data-test="SitesPerCustomerTable"
                               :edit="edit"></SitesPerCustomerTable>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SitesPerCustomerTable from '@/components/customers/sitesPerCustomer/SitesPerCustomerTable';
import TableTitle from '@/components/widgets/tables/TableTitle';

export default {
    name: 'SitesPerCustomer',
    components: { TableTitle, SitesPerCustomerTable },
    props: {
        edit: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
    }),
    computed: {
        ...mapFields('manageSite', [
            'siteFormVisibility'
        ]),
        canAddSite () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.SITE);
        }
    },
    methods: {
        visible () {
            this.siteFormVisibility = true;
        },
        showSiteForm () {
            this.$emit('check-customer');
        }
    }
};
</script>
