<template>
    <Modal :modal-title="$t('configurations.imports.import_sites')"
           :dialog="dialog"
           max-width="800px"
           dense
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ImportSitesForm ref="form"
                             @fetch="$emit('fetch')"></ImportSitesForm>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import ImportSitesForm from '@/components/customers/imports/ImportSitesForm';

export default {
    name: 'ImportSitesModal',
    components: { Modal, ImportSitesForm },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        close () {
            this.$emit('close');
            this.$refs.form.clearFile();
        },
        visibility (event) {
            this.$emit('visibility', event);
        }
    }
};
</script>

<style scoped>

</style>
