<template>
    <GetFile :loader="loader"
             :with-title="false"
             @get-file="getQRCode()">
        <template slot="icon">
            <HintingIcon :icon="ICONS.QR_CODE"
                         class-name="mr-2"
                         :tooltip="$t('management.customers_and_sites.actions.qr')"></HintingIcon>
        </template>
    </GetFile>
</template>

<script>
import HintingIcon from '@/components/widgets/HintingIcon';
import GetFile from '@/components/widgets/buttons/GetFile';
import { ICONS } from '@/constants/icons';

export default {
    name: 'GetAllPointsQrCodes',
    components: { HintingIcon, GetFile },
    props: {
        customerId: {
            type: [Number, String],
            required: true
        }
    },
    data: () => ({
        loader: false,
        ICONS
    }),
    methods: {
        getQRCode () {
            this.loader = true;

            return this.$store.dispatch('manageCustomer/getLocationsPerCustomerQrCode', this.customerId)
                .finally(() => {
                    this.loader = false;
                });
        }
    }
};
</script>

<style scoped>

</style>
