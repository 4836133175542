<template>
    <Modal :modal-title="title"
           :dialog="contactFormVisibility"
           max-width="600px"
           dense
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <TextField v-model="name"
                       :label="$t('management.customers_and_sites.contacts_table.headers.name')"></TextField>
            <TextField v-model="phone"
                       :label="$t('management.customers_and_sites.contacts_table.headers.phone')"></TextField>
            <TextareaField v-model="remarks"
                           :label="$t('management.customers_and_sites.contacts_table.headers.remarks')"></TextareaField>
        </template>

        <template slot="actions">
            <v-layout align-center justify-end>
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import Modal from '@/components/templates/Modal';
import TextField from '@/components/widgets/forms/TextField';
import TextareaField from '@/components/widgets/forms/TextareaField';

export default {
    name: 'ManageCustomerContact',
    components: { Modal, TextField, TextareaField },
    computed: {
        ...mapFields('manageCustomerContact', [
            'contactFormVisibility',
            'editedContactId',
            'contactData.name',
            'contactData.phone',
            'contactData.remarks'
        ]),
        title () {
            return this.editedContactId ? this.$t('customer_contact_form.edit_title') : this.$t('customer_contact_form.add_title');
        }
    },
    methods: {
        close () {
            this.contactFormVisibility = false;
            this.editedContactId = null;
        },
        visibility (event) {
            this.contactFormVisibility = event;
        }
    }
};
</script>

<style scoped>

</style>
