import debounce from 'lodash.debounce';

export default {
    data: () => ({
        searchEmployeesWithAccess: null,
        isEmployeesWithAccessLoading: false,
        debounceGetEmployeesWithAccess: null
    }),
    watch: {
        searchEmployeesWithAccess: {
            handler (val) {
                if (!val || this.isEmployeesWithAccessLoading) {
                    return;
                }

                this.debounceGetEmployeesWithAccess(val);
            }
        }
    },
    methods: {
        getEmployeesWithAccess (title) {
            this.isEmployeesWithAccessLoading = true;
            return this.$store.dispatch('employees/getEmployeesWithAccess', title)
                .finally(() => {
                    this.isEmployeesWithAccessLoading = false;
                });
        }
    },
    created () {
        this.debounceGetEmployeesWithAccess = debounce(this.getEmployeesWithAccess, 1000);
    }
};
