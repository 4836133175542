<template>
    <Modal :modal-title="activeCustomer.name"
           :dialog="dialog"
           max-width="1600px"
           @close="close"
           data-test="modal"
           @visibility="visibility($event)">
        <template slot="subtitle">
            <div>
                <GetAllPointsQrCodes :customer-id="activeCustomer.id"></GetAllPointsQrCodes>
            </div>
        </template>
        <template slot="content">
            <v-row>
                <v-col cols="12">
                    <h3 class="font-weight-medium mb-3">{{ $t('management.customers_and_sites.customer_information.title') }}</h3>
                    <v-sheet height="100%" color="white"
                             class="pa-4"
                             elevation="1">
                    <v-row>
                        <v-col cols="12">
                            <v-progress-linear indeterminate
                                               v-if="activeCustomerLoader"
                                               color="primary"></v-progress-linear>
                        </v-col>
                        <v-col md="8"
                               sm="12"
                               cols="12">

                                <v-layout align-stretch :wrap="$vuetify.breakpoint.xs">
                                    <v-flex sm6
                                            :class="{'bordered pr-4': $vuetify.breakpoint.smAndUp}">
                                        <InformationItem :title="$t('management.customers_and_sites.customer_information.name')"
                                                         @edit="showCustomerForm"
                                                         name="name"
                                                         :text="activeCustomer.name"></InformationItem>
                                        <InformationItem :title="activeCustomer.identification_with_label.identification_1.label"
                                                         @edit="showCustomerForm"
                                                         data-test="textShowCustomerFormButton"
                                                         name="aux_id1"
                                                         :text="activeCustomer.identification_with_label.identification_1.value"></InformationItem>
                                        <InformationItem :title="activeCustomer.identification_with_label.identification_2.label"
                                                         @edit="showCustomerForm"
                                                         name="aux_id2"
                                                         :text="activeCustomer.identification_with_label.identification_2.value"></InformationItem>
                                        <InformationItem :title="activeCustomer.identification_with_label.identification_3.label"
                                                         @edit="showCustomerForm"
                                                         name="aux_id3"
                                                         :text="activeCustomer.identification_with_label.identification_3.value"></InformationItem>
                                        <InformationItem :title="activeCustomer.identification_with_label.identification_4.label"
                                                         @edit="showCustomerForm"
                                                         name="aux_id4"
                                                         :text="activeCustomer.identification_with_label.identification_4.value"></InformationItem>
                                    </v-flex>
                                    <v-flex sm6
                                            :class="{'pl-4': $vuetify.breakpoint.smAndUp}">
                                        <InformationItem :title="$t('management.customers_and_sites.customer_information.address')"
                                                         @edit="showCustomerForm"
                                                         name="address"
                                                         :text="activeCustomer.address"></InformationItem>
                                        <InformationItem :title="$t('management.customers_and_sites.customer_information.email')"
                                                         @edit="showCustomerForm"
                                                         name="email"
                                                         :text="activeCustomer.report_email"></InformationItem>
                                        <InformationItem :title="$t('customer_form.external_id')"
                                                         @edit="showCustomerForm"
                                                         name="external_identifier"
                                                         :text="activeCustomer.external_identifier"></InformationItem>
                                        <InformationItem :title="$t('customer_form.internal_id')"
                                                         @edit="showCustomerForm"
                                                         name="internal_identifier"
                                                         :text="activeCustomer.internal_identifier"></InformationItem>
                                        <InformationItem :title="$t('customer_form.customers_with_access')"
                                                         :clickable="false"
                                                         name="customers_with_access"
                                                         :text="clientsWithAccess"></InformationItem>
                                    </v-flex>
                                </v-layout>
                                <v-layout justify-end>
                                    <v-btn color="secondary"
                                           v-if="canEditCustomer"
                                           data-test="showCustomerFormBtn"
                                           @click="showCustomerForm">{{ $t('buttons.edit') }}</v-btn>
                                </v-layout>

                        </v-col>
                        <v-col md="4"
                               sm="12"
                               cols="12">
                            <div style="height: 280px;" class="mb-5">
                            <Map v-if="dialog"
                                :lat="latitude"
                                 :lng="longitude"></Map>
                            </div>
                            <v-layout align-center justify-space-between>
                                <TextField :label="$t('labels.lat')"
                                           name="latitude"
                                           readonly
                                           class="mr-2"
                                           :value="latitude"></TextField>
                                <TextField :label="$t('labels.long')"
                                           name="longitude"
                                           readonly
                                           class="ml-2"
                                           :value="longitude"></TextField>
                            </v-layout>
                        </v-col>
                    </v-row>
                    </v-sheet>
                </v-col>

                <v-col cols="12" class="mt-4">
                    <SitesPerCustomer :edit="false" class="mt-3"></SitesPerCustomer>
                </v-col>
            </v-row>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import isEmpty from 'lodash.isempty';
import Modal from '@/components/templates/Modal';
import SitesPerCustomer from '@/components/customers/sitesPerCustomer/SitesPerCustomer';
import InformationItem from '@/components/customers/InformationItem';
import Map from '@/components/widgets/Map';
import GetAllPointsQrCodes from '@/components/customers/GetAllPointsQrCodes';
import TextField from '@/components/widgets/forms/TextField';

export default {
    name: 'CustomerDetails',
    components: {
        Modal,
        SitesPerCustomer,
        InformationItem,
        Map,
        GetAllPointsQrCodes,
        TextField
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        showConfirmationDialog: false,
        pdfLoader: false
    }),
    computed: {
        ...mapState('manageCustomer', [
            'activeCustomer',
            'activeCustomerLoader'
        ]),
        ...mapFields('manageCustomer', [
            'customerFormVisibility',
            'editedCustomerId',
            'activeCustomer.latitude',
            'activeCustomer.longitude'
        ]),
        canEditCustomer () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.CUSTOMER);
        },
        clientsWithAccess () {
            if (this.activeCustomer.clients_with_access && !isEmpty(this.activeCustomer.clients_with_access)) {
                const names = Object.values(this.activeCustomer.clients_with_access).map(client => {
                    const _name = client.name || '';
                    const _lastName = client.last_name || '';

                    return `${_name} ${_lastName}`;
                });

                return names.join(', ');
            }
            return '';
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        showCustomerForm () {
            if (this.canEditCustomer) {
                this.editedCustomerId = this.activeCustomer.id;
                this.$store.commit('manageCustomer/SET_CUSTOMER_DATA', this.activeCustomer);
                this.customerFormVisibility = true;
            }
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables";

    .bordered {
        border-right: 1px solid $grey_lighten_2;
    }
</style>
