import debounce from 'lodash.debounce';

export default {
    data: () => ({
        debouncedReturnCoordinates: null
    }),
    methods: {
        returnCoordinates () {
            return this.$store.dispatch('commonActions/getCoordinates', {
                city_id: this.cityId,
                street_and_number: this.streetAndNumber
            });
        }
    },
    mounted () {
        this.debouncedReturnCoordinates = debounce((resolveCallback) => {
            this.returnCoordinates()
                .then(({ data }) => {
                    resolveCallback(data);
                });
        }, 1000);
    }
};
