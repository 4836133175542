import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchTerritories: null,
        isTerritoriesLoading: false,
        debounceGetTerritories: null,
        territoriesPaginationPage: 1
    }),
    computed: {
        ...mapState('generalConfigurations', [
            'totalTerritoriesAmount',
            'territories'
        ])
    },
    watch: {
        searchTerritories: {
            handler (val) {
                if (!val || this.isTerritoriesLoading) {
                    return;
                }

                this.debounceGetTerritories(val);
            }
        }
    },
    methods: {
        getTerritories (title) {
            this.isTerritoriesLoading = true;
            const params = {};

            if (title) {
                params['filter[name][match]'] = title;
            }

            this.$store.dispatch('generalConfigurations/getTerritories', params)
                .then(() => {
                    this.territoriesPaginationPage = 1;
                })
                .finally(() => {
                    this.isTerritoriesLoading = false;
                });
        },
        getTerritoriesLazyLoading () {
            if (this.totalTerritoriesAmount > this.territories.length) {
                this.isTerritoriesLoading = true;

                this.territoriesPaginationPage += 1;

                const params = {
                    'page[number]': this.territoriesPaginationPage
                };

                const _name = this.searchTerritories || this.filtersSearching?.[FILTERS_SEARCHING.TERRITORY];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                this.$store.dispatch('generalConfigurations/getTerritoriesLazyLoading', params)
                    .finally(() => {
                        this.isTerritoriesLoading = false;
                    });
            }
        }
    },
    created () {
        this.debounceGetTerritories = debounce(this.getTerritories, 1000);
    }
};
