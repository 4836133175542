<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs" vid="locationForm">
            <v-layout align-stretch
                      :wrap="$vuetify.breakpoint.xs">
                <v-flex sm5 class="px-4">
                    <TextField :label="$t('labels.customer')"
                               :value="customerTitle"
                               disabled></TextField>
                    <TextField :label="$t('labels.site')"
                               :value="siteTitle"
                               disabled></TextField>
                    <TextField :label="$t('labels.location_name')"
                               name="name"
                               ref="name"
                               :rules="'required'"
                               v-model="name"></TextField>
                    <TextareaField :label="$t('labels.description')"
                                   name="description"
                                   ref="description"
                                   v-model="description"></TextareaField>
                </v-flex>
                <v-flex sm3 class="px-4">
                    <TextField :label="$t('labels.location_code')"
                               name="code"
                               ref="code"
                               v-model="code"></TextField>
                    <TextField :label="$t('labels.nfc_tag')"
                               name="nfccode"
                               ref="nfccode"
                               v-model="nfccode"></TextField>
                    <TextField :label="$t('labels.beacon_identifier')"
                               name="beacon_identifier"
                               ref="beacon_identifier"
                               v-model="beaconIdentifier"></TextField>
                </v-flex>
                <v-flex sm4 class="px-4">
                    <div style="height: 340px;" class="mb-5">
                        <Map draggable
                             :lat="latitude"
                             :lng="longitude"
                             @update-coordinates="updateCoordinates($event)"></Map>
                    </div>
                    <v-layout align-center justify-space-between>
                        <TextField :label="$t('labels.lat')"
                                   name="latitude"
                                   class="mr-2"
                                   rules="required|between:-90,90"
                                   v-model="latitude"></TextField>
                        <TextField :label="$t('labels.long')"
                                   name="longitude"
                                   rules="required|between:-180,180"
                                   class="ml-2"
                                   v-model="longitude"></TextField>
                    </v-layout>
                </v-flex>
            </v-layout>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import TextField from '@/components/widgets/forms/TextField';
import TextareaField from '@/components/widgets/forms/TextareaField';
import Map from '@/components/widgets/Map';
import focusedFields from '@/mixins/focusedFields';

export default {
    name: 'ManageLocationForm',
    components: { ValidationObserver, TextField, TextareaField, Map },
    mixins: [focusedFields],
    computed: {
        ...mapState('manageSite', [
            'activeSite'
        ]),
        ...mapState('manageCustomer', [
            'customerData',
            'activeCustomer'
        ]),
        ...mapState('manageSite', [
            'siteData'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility',
            'locationData.latitude',
            'locationData.longitude',
            'locationData.name',
            'locationData.nfccode',
            'locationData.beaconIdentifier',
            'locationData.description',
            'locationData.code'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        customerTitle () {
            if (this.activeCustomer && this.activeCustomer.name) {
                return this.activeCustomer.name;
            } else if (this.customerData.name) {
                return this.customerData.name;
            } else {
                return '';
            }
        },
        siteTitle () {
            if (this.activeSite && this.activeSite.name) {
                return this.activeSite.name;
            } else if (this.siteData.name) {
                return this.siteData.name;
            } else {
                return '';
            }
        }
    },
    watch: {
        locationFormVisibility: {
            handler (val) {
                if (val) {
                    if (this.latitude === null || this.longitude === null) {
                        if (this.activeSite.latitude && this.activeSite.longitude) {
                            this.latitude = this.activeSite.latitude;
                            this.longitude = this.activeSite.longitude;
                        } else {
                            this.$nextTick(() => {
                                this.getCoordinates(this.activeSite.city_id, this.activeSite.street_and_number);
                            });
                        }
                    }
                } else {
                    this.$store.commit('manageLocation/RESET_LOCATION_DATA');
                }
            },
            immediate: true
        },
        focusedField: {
            handler () {
                this.setFocus(this.$refs, 'name');
            }
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        },
        getCoordinates (cityId, streetAndNumber) {
            return this.$store.dispatch('commonActions/getCoordinates', {
                city_id: cityId,
                street_and_number: streetAndNumber
            })
                .then(({ data }) => {
                    this.latitude = data.latitude;
                    this.longitude = data.longitude;
                });
        },
        updateCoordinates (event) {
            if (event?.lat && event?.lng) {
                this.latitude = event.lat;
                this.longitude = event.lng;
            }
        }
    },
    mounted () {
        this.setFocus(this.$refs, 'name');
        if (this.name.length === 0 && (this.siteTitle || this.customerTitle)) {
            this.name = (this.siteTitle || this.customerTitle).concat('/');
        }
    }
};
</script>

<style scoped>

</style>
