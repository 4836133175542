<template>
    <v-layout align-center justify-space-between>
        <h3 class="font-weight-medium">{{ title }}</h3>
        <v-btn color="secondary"
               outlined
               small
               v-if="buttonVisibility"
               :disabled="disableButton"
               @click="onClick">{{ buttonTitle || '' }}</v-btn>
    </v-layout>
</template>

<script>
export default {
    name: 'TableTitle',
    props: {
        title: {
            type: String,
            required: true
        },
        buttonTitle: {
            type: String,
            required: false
        },
        disableButton: {
            type: Boolean,
            'default': false
        },
        buttonVisibility: {
            type: Boolean,
            'default': true
        }
    },
    methods: {
        onClick () {
            this.$emit('click');
        }
    }
};
</script>

<style scoped>

</style>
