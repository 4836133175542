<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs" vid="siteForm">
            <v-layout align-stretch v-if="siteFormVisibility"
                      :wrap="$vuetify.breakpoint.xs">
                <v-flex sm5 class="px-4">
                    <TextField :label="$t('labels.customer')"
                               name="customerName"
                               :value="customersName"
                               disabled></TextField>
                    <TextField :label="$t('site_form.site_name')"
                               name="name"
                               rules="required"
                               ref="name"
                               v-model="name"></TextField>
                    <v-layout justify-space-between>
                        <Autocomplete :label="$t('labels.city')"
                                      name="zip_code"
                                      rules="required"
                                      :no-filter="true"
                                      ref="zip_code"
                                      :items="filterableZipCodes"
                                      :loading="isCitiesLoading"
                                      :search-input.sync="searchCitiesAndZipCode"
                                      v-model="cityId"
                                      return-object
                                      slot-name="append-item"
                                      @input="updateZipCode($event)"
                        >
                        </Autocomplete>
                        <v-btn color="secondary" outlined
                               v-if="canAddCity"
                               @click="openForm"
                        >{{ $t('labels.new_localization') }}
                        </v-btn>
                    </v-layout>
                    <portal to="modal"><ManageCity @fetch="fetchCities"></ManageCity></portal>
                    <TextField :label="$t('customer_form.street_and_number')"
                               name="streetAndNumber"
                               ref="streetAndNumber"
                               :rules="{ max: 70, required: true }"
                               v-model="streetAndNumber"></TextField>
                    <v-btn color="secondary"
                           outlined
                           :disabled="!streetAndNumber"
                           :loading="loader"
                           class="mb-6"
                           @click="getCoordinates">{{ $t('permissions.refresh_points_geolocation') }}
                    </v-btn>
                    <Autocomplete :label="$t('site_form.customers_with_access')"
                                  name="customers_with_access"
                                  :items="employeesWithAccess"
                                  :loading="isEmployeesWithAccessLoading"
                                  :search-input.sync="searchEmployeesWithAccess"
                                  :multiple="true"
                                  v-model="employeesWithAccessIds"></Autocomplete>
                    <Autocomplete :label="$t('labels.object_groups')"
                                  name="placeGroups"
                                  :multiple="true"
                                  :items="siteGroups"
                                  v-model="placeGroups"></Autocomplete>
                </v-flex>
                <v-flex sm3 class="px-4">
                    <TextField :label="$t('labels.site_code')"
                               name="placeCode"
                               ref="placeCode"
                               v-model="placeCode"></TextField>
                    <TextField :label="$t('site_form.external_id')"
                               name="externalIdentifier"
                               ref="externalIdentifier"
                               v-model="externalIdentifier"></TextField>
                    <TextField :label="$t('site_form.internal_id')"
                               name="internalIdentifier"
                               ref="internalIdentifier"
                               v-model="internalIdentifier"></TextField>
                    <TextareaField :label="$t('labels.remarks')"
                                   name="comment"
                                   ref="comment"
                                   v-model="comment"></TextareaField>
                    <FileInputMultiPart name="newfile"
                       ref="fileinput"
                       :currentFile="file"
                       :multiple="false"
                       :button-title="$t('labels.attachment')"
                       :changeFileQuestion="$t('labels.confirm_change_site_attachment')"
                       class-name="justify-center"
                       :accept="acceptedFiles"
                       input-id="locations"
                       v-model="newfile"
                       :isLoading="attachmentLoader"
                       @remove-current-file="removeAttachment()">
                        <template slot="icon"
                                v-if="attachmentLoader">
                            <CircularLoader class="mr-2"></CircularLoader>
                        </template>
                        <template slot="currentfile">
                            <SiteAttachmentDownload :currentFile="file" />
                        </template>
                    </FileInputMultiPart>
                </v-flex>
                <v-flex sm4 class="px-4">
                    <div style="height: 340px;" class="mb-5">
                        <Map draggable
                             :lat="latitude"
                             :lng="longitude"
                             @update-coordinates="updateCoordinates($event)"></Map>
                    </div>
                    <v-layout align-center justify-space-between>
                        <TextField :label="$t('labels.lat')"
                                   name="latitude"
                                   class="mr-2"
                                   rules="required|between:-90,90"
                                   v-model="latitude"></TextField>
                        <TextField :label="$t('labels.long')"
                                   name="longitude"
                                   rules="required|between:-180,180"
                                   class="ml-2"
                                   v-model="longitude"></TextField>
                    </v-layout>
                </v-flex>
            </v-layout>
        </ValidationObserver>

    </v-sheet>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import { mapState, mapGetters } from 'vuex';
import filters from '@/constants/filters';
import TextField from '@/components/widgets/forms/TextField';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import TextareaField from '@/components/widgets/forms/TextareaField';
import Map from '@/components/widgets/Map';
import focusedFields from '@/mixins/focusedFields';
import debouncedCities from '@/mixins/debounced/debouncedCities';
import debouncedEmployeesWithAccess from '@/mixins/debounced/debouncedEmployeesWithAccess';
import debouncedInspectors from '@/mixins/debounced/debouncedInspectors';
import debouncedReturnCoordinates from '@/mixins/debounced/debouncedReturnCoordinates';
import ManageCity from '@/components/cities/manageCity/ManageCity';
import FileInputMultiPart from '@/components/widgets/forms/FileInputMultiPart';
import CircularLoader from '@/components/widgets/CircularLoader';
import SiteAttachmentDownload from '@/components/customers/manageSite/SiteAttachmentDownload';
import { ActionStatus } from '@/constants/status';

export default {
    name: 'ManageSiteForm',
    components: { ValidationObserver, TextField, Autocomplete, TextareaField, Map, ManageCity, FileInputMultiPart, CircularLoader, SiteAttachmentDownload },
    mixins: [focusedFields, debouncedCities, debouncedEmployeesWithAccess, debouncedInspectors, debouncedReturnCoordinates],
    filters: { ...filters },
    data: () => ({
        extendedProperties: [
            'cityId',
            'zipCode',
            'streetAndNumber',
            'internalIdentifier',
            'externalIdentifier'
        ],
        loader: false,
        acceptedFiles: 'application/pdf',
        newfile: [],
        attachmentLoader: false
    }),
    computed: {
        ...mapFields('manageSite', [
            'siteFormVisibility',
            'editedSiteId',
            'siteData.name',
            'siteData.cityId',
            'siteData.streetAndNumber',
            'siteData.employeesWithAccessIds',
            'siteData.oldEmployeesWithAccessIds',
            'siteData.externalIdentifier',
            'siteData.comment',
            'siteData.placeGroups',
            'siteData.placeCode',
            'siteData.internalIdentifier',
            'siteData.zipCode',
            'siteData.latitude',
            'siteData.longitude',
            'siteData.file'
        ]),
        ...mapState('manageSite', [
            'activeSite',
            'attachmentStatus'
        ]),
        ...mapState('manageCustomer', [
            'activeCustomer'
        ]),
        ...mapState('manageCustomer', [
            'customerData'
        ]),
        ...mapGetters('cities', [
            'filterableZipCodes'
        ]),
        ...mapGetters('sites', [
            'siteGroups'
        ]),
        ...mapGetters('employees', [
            'employeesWithAccess',
            'inspectors'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        canAddCity () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.CITY);
        },
        customersName () {
            return this.activeSite?.clientName || this.activeCustomer?.name || this.customerData?.name || '';
        }
    },
    watch: {
        siteFormVisibility: {
            handler (val) {
                if (val && this.activeSite !== null) {
                    this.getCities(parseInt(this.activeSite.city_id));
                    this.$nextTick(() => {
                        if (!this.latitude && !this.longitude) {
                            this.getCoordinates();
                        }
                    });
                } else {
                    this.$store.commit('manageSite/RESET_SITE_DATA');
                }
            },
            immediate: true
        },
        focusedField: {
            handler () {
                this.setFocus(this.$refs, 'name');
            }
        },
        newfile: {
            handler (val) {
                if (val && val.length > 0) {
                    this.$store.commit('manageSite/CHANGE_ATTACHMENT', val[0]);
                } else {
                    this.$store.commit('manageSite/CHANGE_ATTACHMENT', null);
                }
            },
            deep: true
        },
        attachmentStatus: {
            handler (val) {
                this.attachmentLoader = val === ActionStatus.PENDING;
            },
            immediate: true
        }
    },
    methods: {
        camelToSnakeCase (str) {
            return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        },
        resetValidation () {
            return this.$refs.obs.reset();
        },
        fetchCities () {
            this.searchZipCodes = this.zipCode;
        },
        updateCoordinates (event) {
            if (event) {
                this.latitude = event.lat;
                this.longitude = event.lng;
            }
        },
        openForm () {
            this.$refs.zip_code.isMenuActive = false;
            this.$store.dispatch('generalConfigurations/getCountries').then(() => {
                this.$store.commit('cities/SET_CITY_FORM_VISIBILITY', true);
            });
        },
        updateZipCode (event) {
            if (event) {
                this.zipCode = event.zip_code;
                this.cityId = event.id;
                if (this.activeSite) {
                    this.activeSite.city_id = event.id;
                    this.activeSite.zip_code = event.zip_code;
                }
            } else {
                this.zipCode = '';
                this.cityId = '';
                if (this.activeSite) {
                    this.activeSite.city_id = '';
                    this.activeSite.zip_code = '';
                }
            }
        },
        validate () {
            return this.$refs.obs.validate();
        },
        extendCustomerData (key) {
            const _customerData = this.activeCustomer || this.customerData;
            if (!this[key]) {
                this[key] = (_customerData[key] !== undefined) ? _customerData[key] : _customerData[this.camelToSnakeCase(key)];
            }
        },
        setCoordinates (data) {
            this.latitude = data.latitude;
            this.longitude = data.longitude;
        },
        getCoordinates () {
            this.loader = true;
            if (this.cityId && this.streetAndNumber) {
                this.returnCoordinates()
                    .then(({ data }) => {
                        this.setCoordinates(data);
                    })
                    .finally(() => { this.loader = false; });
            }
        },
        removeAttachment () {
            this.$store.commit('manageSite/REMOVE_ATTACHMENT');
        }
    },
    mounted () {
        this.setFocus(this.$refs, 'name');
        if (this.name.length === 0) {
            this.name = (this.customersName && this.customersName.concat('/')) || this.customersName;
        }
    }
};
</script>

<style scoped lang="scss">
    .mt-1 {
        margin-top: 6px !important;
    }
</style>
