<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <DefaultTable @edit-item="showEditForm($event)"
                      :setup="tableSetup"
                      v-if="canRead"
                      @response="updateStatus"
                      @delete-item="deleteItem($event)"
                      @selected="updateSelected($event)"
                      ref="sitesTable">
<!--            Temporary disable function -->
<!--            <template #search>-->
<!--                <v-col cols="12" md="6" v-if="selectedItems.length > 0">-->
<!--                    <v-btn color="primary" outlined class="ml-4" @click="accessDialog = true">{{$t('actions.assign_to_employees')}}</v-btn>-->
<!--                </v-col>-->
<!--            </template>-->
            <template v-slot:item.name="{ item }">
                <span class="clickable"
                      @click="handleView(item)">{{ item.name }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <HintingIcon :icon="ICONS.DETAILS"
                             :tooltip="$t('actions.view')"
                             class-name="mr-4"
                             data-test="handleViewIcon"
                             @click="handleView(item)"></HintingIcon>
                <HintingIcon :icon="ICONS.CHECKPOINT"
                             :tooltip="$t('actions.move')"
                             :id="item.id"
                             class-name="mr-4"
                             @click="showCustomer(item)">
                </HintingIcon>
                <HintingIcon v-if="tableSetup.ability.u"
                             :icon="ICONS.EDIT"
                             :tooltip="$t('actions.edit')"
                             :id="item.id"
                             data-test="showCustomerFormBtn"
                             @click="showEditForm(item)"
                             class-name="mr-4">
                </HintingIcon>
                <HintingIcon v-if="tableSetup.ability.d"
                             :icon="ICONS.DELETE"
                             :tooltip="$t('actions.delete')"
                             :id="item.id"
                             data-test="showCustomerFormBtn"
                             @click="tryDelete(item)">
                </HintingIcon>
            </template>
        </DefaultTable>
        <v-dialog v-model="dialog" :persistent="true" max-width="600px">
            <v-card>
                <v-card-title>{{$t('labels.customer')}}
                    <v-layout justify-end>
                        <v-btn color="primary" outlined class="ml-4" @click="close">{{ $t('buttons.cancel') }}</v-btn>
                        <v-btn :disabled="loader" color="secondary" class="ml-4" @click="save">{{ $t('buttons.save') }}</v-btn>
                    </v-layout>
                </v-card-title>
                <v-card-text>
                    <ValidationObserver ref="obs">
                    <Autocomplete :label="$t('management.customers_and_sites.headers.name')"
                                  rules="required"
                                  name="customer"
                                  @click="getCustomers(null, null, true)"
                                  @focus="getCustomers(null, null, true)"
                                  @load-more-items="getCustomersLazyLoading(true)"
                                  :items="listingCustomers"
                                  :loading="isCustomersLoading"
                                  :search-input.sync="searchCustomers"
                                  v-model="customer"></Autocomplete>
                    </ValidationObserver>
                </v-card-text>
            </v-card>

        </v-dialog>
        <v-dialog v-model="accessDialog" :persistent="true" max-width="600px">
            <v-card>
                <v-card-title>{{$t('actions.assign_to_employees')}}
                    <v-layout justify-end>
                        <v-btn color="primary" outlined class="ml-4" @click="accessDialog = false">{{ $t('buttons.cancel') }}</v-btn>
                        <v-btn :disabled="loader" color="secondary" class="ml-4" @click="updateEmployeesWithAccess()">{{ $t('buttons.save') }}</v-btn>
                    </v-layout>
                </v-card-title>
                <v-card-text>
                    <Autocomplete :label="$t('site_form.customers_with_access')"
                                  name="customers_with_access"
                                  :items="employeesWithAccess"
                                  :loading="isEmployeesWithAccessLoading"
                                  :search-input.sync="searchEmployeesWithAccess"
                                  :multiple="true"
                                  v-model="employeesWithAccessIds">
                    </Autocomplete>
                </v-card-text>
            </v-card>

        </v-dialog>
        <ManageSite @close-manage="updateTable()" v-if="siteFormVisibility"
                    data-test="ManageSite"></ManageSite>
        <SiteDetails :dialog="showSiteDetails"
                     :hide-link="true"
                     :on-edit="showEditForm"
                     v-if="showSiteDetails"
                     @close="closeSiteDetails"
                     @visibility="showSiteDetails = $event"></SiteDetails>
    </div>
</template>

<script>
import DefaultTable from '@/components/DefaultTable';
import { mapState, mapGetters } from 'vuex';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import HintingIcon from '@/components/widgets/HintingIcon';
import { ICONS } from '@/constants/icons';
import { ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import ManageSite from '@/components/customers/manageSite/ManageSite';
import sitesApi from '@/utils/api/sites';
import sitesApiV2 from '@/utils/api-v2/sites';
import debouncedEmployeesWithAccess from '@/mixins/debounced/debouncedEmployeesWithAccess';
import { uuid } from 'uuidv4';
import { RELATIONSHIPS, INCLUDES } from '@/helpers/jsonapiHelpers';
import SiteDetails from '@/components/customers/SiteDetails';

export default {
    name: 'Sites',
    components: { DefaultTable, Autocomplete, HintingIcon, ValidationObserver, ManageSite, SiteDetails },
    mixins: [
        debouncedCustomers,
        debouncedEmployeesWithAccess
    ],
    data: () => ({
        ICONS,
        dialog: false,
        accessDialog: false,
        customer: null,
        loader: false,
        siteId: null,
        selectedItems: [],
        employeesWithAccessIds: [],
        defaultParams: {
            'include': 'customer'
        }
    }),
    computed: {
        ...mapGetters('employees', [
            'employeesWithAccess'
        ]),
        ...mapFields('manageSite', [
            'siteFormVisibility',
            'editedSiteId',
            'showSiteDetails'
        ]),
        ...mapState('manageSite', [
            'activeSite'
        ]),
        ...mapGetters('customers', [
            'listingCustomers'
        ]),
        canRead () {
            return this.$can(this.$abilityActions.READ, this.$abilitySubjects.SITE);
        },
        canEditSite () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.SITE);
        },
        canRemoveSite () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.SITE);
        },
        tableSetup () {
            return {
                // Temporary disable function
                // showSelect: true,
                title: this.$t('labels.sites'),
                deleteInfo: this.$t('messages.delete_confirmation'),
                searchBy: 'name',
                headers: [
                    {
                        text: 'No',
                        value: 'seq',
                        sortable: false
                    },
                    {
                        text: 'id',
                        value: 'id',
                        sortable: false
                    },
                    {
                        text: this.$t('labels.name'),
                        value: 'name',
                        icon: false,
                        sortable: true
                    },
                    {
                        text: this.$t('dashboard.stats.locations_number'),
                        value: 'points_count',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('labels.street_and_number'),
                        value: 'street_and_number',
                        icon: false,
                        sortable: true
                    },
                    {
                        text: this.$t('labels.city'),
                        value: 'city_name',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('labels.customer'),
                        value: 'customerName',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('labels.actions'),
                        value: 'actions',
                        align: 'center',
                        width: '170px',
                        sortable: false
                    }
                ],
                endpoint: 'sites',
                customItems: this.mapItems,
                fetchParams: this.defaultParams,
                ability: {
                    c: false,
                    r: this.canRead,
                    u: this.canEditSite,
                    d: this.canRemoveSite
                },
                sortable: {
                    id: 'desc',
                    street_and_number: 'desc',
                    name: 'asc'
                },
                sortingKeys: {
                    'id': 'id',
                    'street_and_number': 'street_and_number',
                    'name': 'name'
                }
            };
        }
    },
    methods: {
        closeSiteDetails () {
            this.$store.dispatch('manageSite/cleanActiveSiteInformation');
            this.showSiteDetails = false;
            this.updateTable();
        },
        getActiveSite (id) {
            return this.$store.dispatch('manageSite/getActiveSite', id);
        },
        updateStatus (resp) {
            return this.$store.commit('customers/SET_POINT_CREATION_AVAILABLE', resp.data.meta.point_creation_available);
        },
        handleView (item) {
            this.getActiveSite(item.id)
                .then(() => {
                    this.showSiteDetails = true;
                });
        },
        updateSelected (e) {
            if (Array.isArray(e)) {
                if (e.length > 0) {
                    this.selectedItems = e.map(e => e.id);
                } else this.selectedItems = e;
            }
        },
        updateRelated (siteId) {
            const payload = {
                data: {
                    id: siteId,
                    type: 'places',
                    attributes: {
                        employees_with_access_ids: this.employeesWithAccessIds
                    },
                    relationships: {
                        place_employees: {
                            data: []
                        }

                    }
                },
                included: []
            };
            this.employeesWithAccessIds.forEach(employeeId => {
                const tempId = uuid();
                payload.included.push(INCLUDES.ADD_PLACE_EMPLOYEE(tempId, employeeId));
                payload.data.relationships.place_employees.data.push(RELATIONSHIPS.CREATE.PLACE_EMPLOYEE(tempId));
            });
            return payload;
        },
        async updateEmployeesWithAccess () {
            this.loader = true;
            const promises = [];
            if (this.selectedItems.length > 0 && this.employeesWithAccessIds.length > 0) {
                this.selectedItems.forEach(el => {
                    promises.push(sitesApi.update(el, this.updateRelated(el)));
                });
            }
            return await Promise.all(promises).then(() => {
                this.closeAccessDialog();
            });
        },
        mapItems (items, included) {
            return items.map(e => {
                return {
                    id: e.id,
                    ...e.attributes,
                    customerId: e.relationships?.customer?.data?.id || null,
                    customerName: included.find(i => i.id === e.relationships?.customer?.data?.id)?.attributes?.name || null
                };
            });
        },
        showEditForm (item) {
            this.$store.dispatch('manageSite/getActiveSite', item.id)
                .then(() => {
                    this.$store.commit('manageSite/SET_SITE_DATA', this.activeSite);
                    item.customerId && this.$store.commit('manageCustomer/SET_CUSTOMER_NAME_AND_ID', { id: item.customerId, name: item.customerName });
                    this.editedSiteId = item.id;
                    this.siteFormVisibility = true;
                });
        },
        showCustomer (item) {
            this.getCustomers(null, item.customerId, null);
            this.dialog = true;
            this.customer = item.customerId;
            this.siteId = item.id;
        },
        close () {
            this.dialog = false;
            this.customer = null;
            this.siteId = null;
            this.loader = false;
        },
        closeAccessDialog () {
            this.accessDialog = false;
            this.$refs.sitesTable.selected = [];
            this.employeesWithAccessIds = [];
            this.loader = false;
        },
        updateTable () {
            this.$refs.sitesTable.fetchData();
        },
        save () {
            this.loader = true;
            this.$refs.obs.validate().then(valid => {
                if (valid) {
                    const payload = {
                        data: {
                            id: this.siteId,
                            type: 'places',
                            relationships: {
                                customer: {
                                    data: {
                                        type: 'customer',
                                        id: this.customer
                                    }
                                }
                            }
                        }
                    };
                    return sitesApi.update(this.siteId, payload)
                        .then(() => {
                            this.close();
                            this.updateTable();
                        });
                } else {
                    this.loader = false;
                }
            });
        },
        tryDelete (item) {
            this.$refs.sitesTable.tryDeleteItem(item);
        },
        deleteItem (item) {
            return sitesApiV2.deleteSite(item.id).then(() => {
                this.close();
                this.updateTable();
            });
        }
    },
    created () {
        this.$store.dispatch('employees/getEmployeesWithAccess');
    }
};
</script>
